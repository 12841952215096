import React from 'react';

import styles from "./renovationsPage.module.css";

const Renovations = () => (
    <div className={styles.container}>
        <div>
            <h1>Renovations</h1>
            <p>Home and Bliss Renovations offers Calgary home owners professional and
                affordable residential renovations. We offer both interior and exterior home
                remodel services in the Calgary area. We understand even thinking about a
                potential home renovation can be an intimidating process however with our
                remodel experiences we can help put your homeowner mind at ease. Our reputation
                in the YYC community is vital to us. We want you to be able to enjoy every
                square foot of your home to it’s fullest potential. We will strive to make our
                best effort to deliver a final renovation product without causing your family
                any unwarranted headaches.
            </p>
            <h2>Calgary Home Renovation Services we offer:</h2>
            <div className={styles.servicesContainer}>
                <div>
                    <h3>Interior</h3>
                    <ul>
                        <li>Kitchen renovations
                        </li>
                        <li>Bathroom renovations
                        </li>
                        <li>Basement developments & remodels
                        </li>
                        <li>Painting services
                        </li>
                        <li>Flooring installations
                        </li>
                        <li>Lighting installations</li>
                        <li>Interior finishes
                        </li>
                        <li>Tiling & grouting services
                        </li>
                        <li>Wallpaper installations
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Exterior
                    </h3>
                    <ul>
                        <li>Retaining walls
                        </li>
                        <li>Foundations
                        </li>
                        <li>Window & door installations
                        </li>
                        <li>Weatherproofing
                        </li>
                        <li>Garage builds
                        </li>
                        <li>Decks and fencing
                        </li>
                        <li>Patio stone installations
                        </li>
                    </ul>
                </div>
            </div>
            <p>The first step in pre-construction is getting in contact with us. We would be
                happy to assist with stopping by for a walk-through of the renovation space and
                provide you with a free estimate. We highly recommend a walk through of the
                remodel space so we can review project details and take detailed measurements to
                provide you with a more accurate quote.
                <br></br>
                <br></br>Once the renovation quote has been approved, we can begin the
                renovation process. We will ensure measures are taken to protect the surrounding
                spaces before beginning the demolition. Safety and quality control are
                continually top of mind for us. Each remodel project is unique and the duration
                will depend on the scope of the renovation. Upon completing the renovation we
                will ensure to do a detailed cleanup of the space before meeting with you to
                complete a final walk through and sign off on a final quality check. Our highest
                priorities at Home and Bliss Renovations are customer satisfaction and quality
                workmanship.
                <br></br>
                <br></br>Connect with us today to learn more.
            </p>
        </div>
    </div>
);

export default Renovations;