import React from 'react';
import Layout from '../components/layout';

import Content from '../components/renovationsPage';
import Contact from '../components/contact';
import Footer from '../components/footer';

const RenovationsPage = () => (
    <Layout>
        <Content></Content>
        <Contact></Contact>
        <Footer></Footer>
    </Layout>
)

export default RenovationsPage
